<!-- COMPONENTE DE SLIDER DE PRODUCTOS EN DESKTOP -->

<template>
    <div class="q-ml-md q-mr-xl">
        <q-item class="justify-between">
            <router-link to="/products">
                <h6 class="q-mt-xs q-mb-xs q-ml-md text-weight-bold text-left">
                    {{ $t("Home.catalog") }}
                </h6>
            </router-link>
            <router-link to="/products">
                <q-card-section class="underline-text title-products q-pa-sm text-weight-bold">
                    {{ $t("Home.see-all") }}
                    <q-tooltip anchor="bottom middle" self="top middle">
                        {{ $t("BuyingProcess.products.see-products") }}
                    </q-tooltip>
                </q-card-section>
            </router-link>
        </q-item>

        <!-- Grid de productos -->

        <div class="row q-pa-xs justify-center slider-products-desktop"
            style="overflow: scroll; height: 500px; overflow-x: hidden" v-if="products.length > 0">
            <div class="q-ma-sm q-ml-md" v-for="product in products.slice(0, 20)" :key="product.id"
                v-show="product.visible">
                <!-- Si hay más de una imagen, agarra la primera; si no, pone la imagen por defecto -->

                <img class="rounded-borders cursor-pointer" :src="product.images.length > 0
                        ? getProductImageUrl(product.images[0])
                        : defaultImageApp
                    " :alt="product.name" loading="lazy" clickable @click="showItemDesktop()"
                    :disabled="points.points.points > product.value ? disabled : 0" />

                <div class="text-subtitle1 text-left q-ml-md">
                    {{ product.name }}
                </div>
                <div class="text-subtitle2 text-left q-ml-md">
                    {{ product.value }} {{ settings.currency_abbreviation }}
                </div>
            </div>
        </div>
        <div v-else>{{ $t("BuyingProcess.products.no-products") }}</div>

        <div>
            <ProductPage v-model="dialog" :product="products.id" :points="points" />
        </div>
    </div>
</template>

<script>
import { defineAsyncComponent, ref } from "vue";
import { mapActions } from "vuex";
import settings from "@/mixins/settings";
import { getProductImageUrl } from "@/services/helpers/ProductImages.js"


const ProductPage = defineAsyncComponent(() =>
    import("../../module-3-transactions/components/ProductPage.vue")
);

export default {
    mixins: [settings],

    components: {
        ProductPage,
    },
    props: {
        points: {
            type: Object,
        },
    },
    setup() {
        return {
            slide: ref(1),
            dialog: false,
        };
    },
    data() {
        return {
            defaultImage: this.defaultImageApp,
            disabled: null,
        };
    },
    computed: {
        products() {
            return this.$store.state.products.products;
        },

    },
    methods: {
        ...mapActions("products", ["getProducts"]),

        getProductImageUrl,

        showItemDesktop() {
            this.$router.push({ name: "Products" });
        },
    },
    mounted() {
        this.getProducts();

    },
};
</script>

<style lang="scss" scoped>
@import "~quasar-variables";

.q-img,
img {
    border-radius: 25px 25px 25px 0px;
    height: 177px;
    margin: 0.5rem;
    object-fit: contain;
    width: 160px;
}

.text-subtitle1 {
    color: $dark;
    font-size: 13px;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 150px;
}

.text-subtitle2 {
    color: $gray2;
    font-size: 15px;
    font-weight: 400;
}

.title-products {
    color: v-bind("settings.color_accent");
}

.slider-products-desktop {
    width: 100%;
}
</style>
